import {
  BitcoinIcon,
  ChevronRightIcon,
  MessageCircleDashedIcon,
  RocketIcon,
} from "lucide-react";
import { Button, buttonVariants } from "../ui/button";
import { showMessages } from "@intercom/messenger-js-sdk";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import Heroimage3 from "/images/assets/heroimage.svg";

export default function HeroSection(): JSX.Element {
  const url = import.meta.env.VITE_DASHBOARD_URL;

  return (
    <section className="w-full min-h-screen grid place-items-center pt-[63px] bg-gradient-to-b from-primary/10 to-background">
      <div className="container mx-auto px-6 py-12 lg:py-40">
        <div className="flex items-center">
          {/* Left Content */}
          <div className="flex-1 lg:flex-grow-0 lg:min-w-[430px] lg:max-w-screen-sm flex flex-col items-center lg:items-start gap-6 text-center lg:text-left">
            {/* TrustPilot Badge */}
            <div className="relative text-xs flex items-center gap-4 rounded-full px-4 py-1 md:text-sm leading-6 text-foreground ring-1 ring-white/20 hover:ring-white/30">
              <span className="font-semibold text-green-600">
                We&apos;re on TrustPilot
              </span>
              <span className="h-4 w-px bg-white" aria-hidden="true" />
              <a
                href="https://trustpilot.com/review/thunderproxy.com"
                className="flex items-center gap-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check our reviews
                <ChevronRightIcon className="h-5 w-5 text-muted-foreground" />
              </a>
            </div>

            {/* Headings */}
            <h1 className="text-3xl lg:text-5xl font-bold leading-tight">
              Unbeatable quality, incredible prices
            </h1>
            <div className="text-lg lg:text-xl opacity-90">
              <p>Lightning-Fast Web Data Collection</p>
              Without Hassles
            </div>

            {/* CTA Buttons */}
            <div className="flex flex-col md:flex-row gap-4">
              <Link
                to={`${url}/auth/register`}
                className={cn(buttonVariants({ size: "lg" }))}
              >
                <RocketIcon className="size-4 mr-2" />
                Get started
              </Link>
              <Button onClick={showMessages} size="lg" variant="secondary">
                <MessageCircleDashedIcon className="size-4 mr-2" />
                Talk to an expert
              </Button>
            </div>

            {/* Payment Info */}
            <div className="flex items-center gap-1 text-xs text-muted-foreground">
              <BitcoinIcon className="size-4" />
              Pay with crypto & credit cards
            </div>
          </div>

          {/* Right Content (Hero Image) */}
          <div className="flex-1 w-full hidden lg:block">
            <img src={Heroimage3} />
          </div>
        </div>
      </div>
    </section>
  );
}
