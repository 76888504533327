import { MenuIcon, XIcon } from "lucide-react";
import { useState } from "react";
import Logo from "/images/assets/logo_v2.svg";
import MobileNav from "./mobile-nav";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";

export interface LinkItem {
  label: string;
  path: string;
}

export const links: LinkItem[] = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Pricing",
    path: "/pricing",
  },
  {
    label: "Locations",
    path: "/locations",
  },
  {
    label: "Support",
    path: "/support",
  },
];

export default function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const url = import.meta.env.VITE_DASHBOARD_URL;

  return (
    <>
      <div className="w-full fixed top-0 z-50 border-b bg-background">
        <div className="flex justify-between container items-center px-4 md:px-6 mx-auto h-16 ">
          <div className="flex items-center justify-start w-1/3">
            <button
              className="flex justify-center gap-1 items-center border-bottom border-white -mt-1"
              onClick={() => navigate("/")}
            >
              <img src={Logo} className="object-contain h-7 -mb-1" alt="" />
              <span className="leading-5 text-foreground font-semibold text-lg md:text-2xl">
                thunderproxy
              </span>
            </button>
          </div>
          <div className="flex items-center justify-center w-1/3">
            <ul className="hidden lg:flex gap-5 font-medium">
              {links.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? "text-foreground"
                        : "text-muted-foreground  hover:text-foreground transition"
                    }
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center justify-end w-1/3">
            <span className="hidden lg:block text-xs font-medium mr-2">
              Already a customer?
            </span>
            <a href={`${url}`} aria-label="Vai alla pagina di login">
              <Button className="hidden lg:block">Login</Button>
            </a>
            <button
              type="button"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="lg:hidden border rounded text-foreground justify-center items-center p-2"
              aria-label={showMobileMenu ? "Chiudi menu" : "Apri menu"}
            >
              {showMobileMenu ? (
                <XIcon className="w-6 h-6" />
              ) : (
                <MenuIcon className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <MobileNav open={showMobileMenu} onOpenChange={setShowMobileMenu} />
      )}
    </>
  );
}
