import loadable from "@loadable/component";
import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";
import Header from "../header";
import { useEffect } from "react";
import { setCookie } from "react-use-cookie";

const TrackPageView = loadable(() => import("../track-page-view"));

const Footer = loadable(() => import("../footer"));

export default function RootLayout(): JSX.Element {
  const [searchParams] = useSearchParams();

  const saveGclid = (gclid: string) => {
    setCookie("gclid", gclid, {
      domain: import.meta.env.VITE_BASE_DOMAIN,
      Secure: import.meta.env.MODE !== "development",
    });
  };

  const saveCampaign = (campaignCode: string) => {
    setCookie("campaign", campaignCode, {
      domain: import.meta.env.VITE_BASE_DOMAIN,
      Secure: import.meta.env.MODE !== "development",
    });
  };

  useEffect(() => {
    const campaignCode = searchParams.get("c");
    if (campaignCode) {
      saveCampaign(campaignCode);
    }

    const gclid = searchParams.get("gclid");
    if (gclid) {
      saveGclid(gclid);
    }
  }, [searchParams]);

  return (
    <div className="bg-background h-full">
      <div className="relative min-h-screen">
        <Header />
        <Outlet />
        <ScrollRestoration />
      </div>
      <Footer />
      <TrackPageView />
    </div>
  );
}
